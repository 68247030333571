import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { CSVLink } from "react-csv";
import { performanceMetrics_csv } from "../../../data/modules/PerformanceMetrics";

const PerformanceTracking = ({ data }) => {
  const [tab, setTab] = useState(0);

  return (
    <section className="text-sm text-gray-700">
      <div className="flex gap-2 items-center justify-end">
        {/* <FaArrowLeft className="text-green-600 font-bold text-lg" />{" "} */}
        <p className="bg-green-600 rounded-sm px-2 text-white uppercase">
          Priority
        </p>
        indicates prioritized KPIs
        {/* Export to CSV */}
        <CSVLink
          data={performanceMetrics_csv}
          filename="performance_metrics.csv"
          className="border-primary border p-2 ml-10 hover:bg-primary hover:text-white rounded-md"
          title="Export to CSV"
        >
          Export as .csv
        </CSVLink>
      </div>
      <div className="flex gap-5 pt-5">
        {Object.keys(data).map((metric, index) => (
          <button
            type="button"
            key={index}
            onClick={() => setTab(index)}
            className={`w-1/4 py-2 px-4 rounded-t-md animate-fade-up font-bold text-sm text-center mt-2 ${
              tab === index
                ? "bg-primary text-white"
                : "bg-gray-200 bg-opacity-50 hover:bg-primary hover:text-white"
            }`}
          >
            {metric}
          </button>
        ))}
      </div>
      {Object.values(data).map((metricData, index) => (
        <div key={index} className="animate-fade-up">
          {tab === index && (
            <ul
              style={{ listStyleType: "square" }}
              className="p-5 rounded-b-md bg-gray-100 border-primary border"
            >
              {Object.entries(metricData).map(([title, values], id) => (
                <li key={id} className="ml-2 py-2">
                  <p className="flex gap-2 items-center">
                    <span>
                      <span className="font-bold">{title}:</span>{" "}
                      {values.description}{" "}
                    </span>
                    {values.kpi_priority && (
                      <p className="bg-green-600 rounded-sm px-2 text-white uppercase">
                        Priority
                      </p>
                      // <FaArrowLeft className="text-lg" />
                    )}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </section>
  );
};

export default PerformanceTracking;
