// ***************************************************************  All Power BI Dashboards  ***************************************************************

import { deployment2024 } from "./data";

export const dashboards = {
  // Sales Prophet
  naiad2024: {
    marketIntelligence: {
      deployment2024: {
        reportId: "52cc5e6a-25fd-4ca1-b2b1-e799d22dd8aa",
      },
    },
    customerSegmentation: {
      deployment2024: {
        reportId: "0125d33a-f561-4220-a3f1-fc7f67a13259",
      },
    },
    agentProductivity: {
      deployment2024: {
        reportId: "4dbe84de-8ca7-453f-b532-4285a411cc8a",
      },
    },
    salesOpportunity: {
      deployment2024: {
        reportId: "91c3d8b8-e5aa-475a-9524-35b7c645f1ac",
      },
    },
    growthPlan: {
      deployment2024: {
        reportId: null,
      },
    },
    metricsForPerformanceTracking: {
      deployment2024: {
        reportId: null,
      },
    },
  },
};
