export const performanceMetrics = {
  "Sales Pipeline": {
    "Client Meetings/Calls": {
      description: (
        <span>
          Count of in-person meetings or calls held by the agent with
          prospective customers
        </span>
      ),
      kpi_priority: false,
    },
    "Client Prospects": {
      description: (
        <span>
          Universe of customers identified by the agent as a potential insurance
          or annuity buyer
        </span>
      ),
      kpi_priority: true,
    },
    "Client Submissions": {
      description: (
        <span>
          Distinct count of customers who submitted an app with OneAmerica agent
        </span>
      ),
      kpi_priority: true,
    },
    "Clients Declined": {
      description: (
        <span>
          Distinct count of customers who submitted an app but were declined for
          underwriting reasons
        </span>
      ),
      kpi_priority: false,
    },
    "Clients Withdrawn": {
      description: (
        <span>
          Distinct count of customers who submitted an app but did not complete
          the underwriting process
        </span>
      ),
      kpi_priority: false,
    },
    "Clients Issued": {
      description: (
        <span>
          Distinct count of customers who submitted an app and were issued a
          OneAmerica policy
        </span>
      ),
      kpi_priority: false,
    },
    "Clients Not Taken": {
      description: (
        <span>
          Distinct count of customers who were issued a OneAmerica policy but
          did not purchase
        </span>
      ),
      kpi_priority: false,
    },
    "Clients Placed": {
      description: (
        <span>
          Distinct count of customers who were issued a OneAmerica policy and
          purchased
        </span>
      ),
      kpi_priority: false,
    },
    "Client Submission to Prospect Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Client Submissions</span> to{" "}
          <span className="font-semibold">Client Prospects</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Client Decline Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Clients Declined</span> to{" "}
          <span className="font-semibold">Client Submissions</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Client Withdrawn Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Clients Withdrawn</span> to{" "}
          <span className="font-semibold">Client Submissions</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Client Issue Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Clients Issued</span> to{" "}
          <span className="font-semibold">Client Submissions</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Client Sold Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Clients Placed</span> to{" "}
          <span className="font-semibold">Clients Issued</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Client Not Taken Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Clients Not Taken</span> to{" "}
          <span className="font-semibold">Clients Issued</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Client Placement Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Client Placed</span> to{" "}
          <span className="font-semibold">Client Submissions</span>
        </span>
      ),
      kpi_priority: true,
    },
  },
  "Sales Production": {
    "Policy Submissions": {
      description: (
        <span>Count of new business applications based on Received Date</span>
      ),
      kpi_priority: false,
    },
    "Sold Policies": {
      description: <span>Count of placed policies based on Paid Date</span>,
      kpi_priority: true,
    },
    "Operational Placement Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Sold Policies</span> to{" "}
          <span className="font-semibold">Policy Submissions</span> (with 3
          month lag for Policy Submissions)
        </span>
      ),
      kpi_priority: false,
    },
    "Weighted Sales": {
      description: (
        <span>
          Sum of annual premium equivalent for{" "}
          <span className="font-semibold">Sold Policies</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Unweighted Sales": {
      description: (
        <span>
          Sum of gross premium sales and deposits for{" "}
          <span className="font-semibold">Sold Policies</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Sold Face Amount": {
      description: (
        <span>
          Sum of face amount or death benefit for{" "}
          <span className="font-semibold">Sold Policies</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Average Policy Size": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Weighted Sales</span> to{" "}
          <span className="font-semibold">Sold Policies</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Average Sold Face Amount": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Sold Face Amount</span> to{" "}
          <span className="font-semibold">Sold Policies</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Sold Policies to New Customers": {
      description: (
        <span>
          Count of <span className="font-semibold">Sold Policies</span> to
          customers with no prior OneAmerica policy
        </span>
      ),
      kpi_priority: false,
    },
    "Sold Policies to Inforce Customers": {
      description: (
        <span>
          Count of <span className="font-semibold">Sold Policies</span> to
          existing OneAmerica customers with an inforce policy
        </span>
      ),
      kpi_priority: false,
    },
    "New Business Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Sold Policies</span> to{" "}
          <span className="font-semibold">New Customers</span> to{" "}
          <span className="font-semibold">Sold Policies</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Cross-Sell Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Sold Policies</span> to{" "}
          <span className="font-semibold">Inforce Customers</span> to{" "}
          <span className="font-semibold">Sold Policies</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Product Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Sold Policies</span> to{" "}
          <span className="font-semibold">Clients Placed</span>
        </span>
      ),
      kpi_priority: true,
    },
  },
  "Inforce & Customer Penetration": {
    "Inforce Clients": {
      description: (
        <span>
          Distinct count of customers with an active OneAmerica policy
        </span>
      ),
      kpi_priority: true,
    },
    "Inforce Policies": {
      description: <span>Count of active OneAmerica policies inforce</span>,
      kpi_priority: false,
    },
    "Inforce Products": {
      description: (
        <span>
          Distinct count of Life, Annuity, or Care Solution products across{" "}
          <span className="font-semibold">Inforce Clients</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Inforce Premium": {
      description: (
        <span>
          Sum of annual premium equivalent for{" "}
          <span className="font-semibold">Inforce Policies</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Inforce Face Amount": {
      description: (
        <span>
          Sum of face amount or death benefit for{" "}
          <span className="font-semibold">Inforce Policies</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Inforce Polices per Client": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Inforce Policies</span> to{" "}
          <span className="font-semibold">Inforce Clients</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Inforce Products per Client": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Inforce Products</span> to{" "}
          <span className="font-semibold">Inforce Clients</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Inforce Premium per Client": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Inforce Premium</span> to{" "}
          <span className="font-semibold">Inforce Clients</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Average Inforce Face Amount": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Inforce Face Amount</span> to{" "}
          <span className="font-semibold">Inforce Policies</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Multi-policy Clients": {
      description: (
        <span>
          Distinct count of{" "}
          <span className="font-semibold">Inforce Clients</span> that own more
          than one <span className="font-semibold">Inforce Policy</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Multi-product Clients": {
      description: (
        <span>
          Distinct count of{" "}
          <span className="font-semibold">Inforce Clients</span> that own more
          than one <span className="font-semibold">Inforce Product</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Multi-policy Client Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Multi-policy Clients</span>{" "}
          to <span className="font-semibold">Inforce Clients</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Multi-product Client Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Multi-product Clients</span>{" "}
          to <span className="font-semibold">Inforce Clients</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Terminated Policies": {
      description: (
        <span>
          Count of <span className="font-semibold">Inforce Policies</span> that
          have lapsed due to death, cancellation, etc. during the reporting
          period
        </span>
      ),
      kpi_priority: false,
    },
    "Terminated Premium": {
      description: (
        <span>
          Sum of <span className="font-semibold">Inforce Premium</span> that has
          lapsed due to death, cancellation, etc. during the reporting period
        </span>
      ),
      kpi_priority: false,
    },
    "Policy Lapse Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Terminated Policies</span> to{" "}
          <span className="font-semibold">Inforce Policies</span> at the start
          of the reporting period
        </span>
      ),
      kpi_priority: true,
    },
    "Premium Lapse Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Terminated Premium</span> to{" "}
          <span className="font-semibold">Inforce Premium</span> at the start of
          the reporting period
        </span>
      ),
      kpi_priority: false,
    },
  },
  "Product Mix": {
    "Care Solutions Sold Policy Mix": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Sold Policies</span> subset
          for Care Solutions product to{" "}
          <span className="font-semibold">Sold Policies</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Care Solutions Weighted Sales Mix": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Weighted Sales</span> subset
          for Care Solutions product to{" "}
          <span className="font-semibold">Weighted Sales</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Care Solutions Inforce Premium Mix": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Inforce Premium</span> subset
          for Care Solutions product to{" "}
          <span className="font-semibold">Inforce Premium</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Care Solutions Inforce Policy Mix": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Inforce Policies</span>{" "}
          subset for Care Solutions product to{" "}
          <span className="font-semibold">Inforce Policies</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Care Solutions Agent Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Active Agents</span> subset
          that has sold a Care Solutions product to{" "}
          <span className="font-semibold">Active Agents</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Annuity Sold Policy Mix": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Sold Policies</span> subset
          for Annuity product to{" "}
          <span className="font-semibold">Sold Policies</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Annuity Weighted Sales Mix": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Weighted Sales</span> subset
          for Annuity product to{" "}
          <span className="font-semibold">Weighted Sales</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Annuity Inforce Premium Mix": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Inforce Premium</span> subset
          for Annuity product to{" "}
          <span className="font-semibold">Inforce Premium</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Annuity Inforce Policy Mix": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Inforce Policies</span>{" "}
          subset for Annuity product to{" "}
          <span className="font-semibold">Inforce Policies</span>
        </span>
      ),
      kpi_priority: false,
    },
    "Annuity Agent Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Active Agents</span> subset
          that has sold a Annuity product to{" "}
          <span className="font-semibold">Active Agents</span>
        </span>
      ),
      kpi_priority: false,
    },
  },
  "Agents & Recruiting": {
    "Active Agents": {
      description: (
        <span>
          Count of producers employed by OneAmerica or independent producers
          licensed to sell OneAmerica products
        </span>
      ),
      kpi_priority: true,
    },
    "Non-Producing Agents": {
      description: (
        <span>
          Count of <span className="font-semibold">Active Agents</span> subset
          that does not have a{" "}
          <span className="font-semibold">Sold Policy</span> in the last 12
          months
        </span>
      ),
      kpi_priority: false,
    },
    "Producing Agents": {
      description: (
        <span>
          Count of <span className="font-semibold">Active Agents</span> subset
          that has produced at least one{" "}
          <span className="font-semibold">Sold Policy</span> in the last 12
          months
        </span>
      ),
      kpi_priority: false,
    },
    "Producing Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Producing Agents</span> to{" "}
          <span className="font-semibold">Active Agents</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Average Agent Production": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Weighted Sales</span> in the
          last 12 months divided by{" "}
          <span className="font-semibold">Producing Agents</span>
        </span>
      ),
      kpi_priority: false,
    },
    "High Producing Agents": {
      description: (
        <span>
          Count of <span className="font-semibold">Producing Agents</span> that
          have more than $50K{" "}
          <span className="font-semibold">Weighted Sales</span> in the last 12
          months
        </span>
      ),
      kpi_priority: false,
    },
    "Low Producing Agents": {
      description: (
        <span>
          Count of <span className="font-semibold">Producing Agents</span> that
          have less than $10K{" "}
          <span className="font-semibold">Weighted Sales</span> in the last 12
          months
        </span>
      ),
      kpi_priority: false,
    },
    "High Producing Agent Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">High Producing Agents</span>{" "}
          to <span className="font-semibold">Producing Agents</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Low Producing Agent Ratio": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Low Producing Agents</span>{" "}
          to <span className="font-semibold">Producing Agents</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Agent Attrition": {
      description: (
        <span>
          Subset of <span className="font-semibold">Active Agents</span> that
          terminated or had their license expired during the reporting period
        </span>
      ),
      kpi_priority: false,
    },
    "Agent Attrition Rate": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Agent Attrition</span> to{" "}
          <span className="font-semibold">Active Agents</span> at the start of
          the reporting period
        </span>
      ),
      kpi_priority: true,
    },
    "New Agents Recruited": {
      description: (
        <span>
          Subset of <span className="font-semibold">Active Agents</span> that
          were hired or onboarded during the reporting period
        </span>
      ),
      kpi_priority: false,
    },
    "Net Agents Added": {
      description: (
        <span>
          Difference between{" "}
          <span className="font-semibold">New Agents Recruited</span> and{" "}
          <span className="font-semibold">Agent Attrition</span> during the
          reporting period
        </span>
      ),
      kpi_priority: true,
    },
    "Average Agent Tenure": {
      description: (
        <span>
          Average tenure with OneAmerica in years for{" "}
          <span className="font-semibold">Active Agents</span>
        </span>
      ),
      kpi_priority: false,
    },
  },
  "Implied Metrics & Ratios": {
    "Market Size": {
      description: (
        <span>
          Sum of annualized sales premium for relevant life and annuity products
          by geography across all carriers
        </span>
      ),
      kpi_priority: false,
    },
    "Market Share": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Weighted Sales</span> to{" "}
          <span className="font-semibold">Market Size</span> for life products;
          ratio of
          <span className="font-semibold">Unweighted Sales</span> to{" "}
          <span className="font-semibold">Market Size</span> for annuities
        </span>
      ),
      kpi_priority: true,
    },
    "Market Agents": {
      description: (
        <span>
          Count of resident, active producers licensed to sell life and annuity
          products by geography across all carriers
        </span>
      ),
      kpi_priority: false,
    },
    "Agent Share": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Active Agents</span> to{" "}
          <span className="font-semibold">Market Agents</span> (more relevant
          for independent channel)
        </span>
      ),
      kpi_priority: true,
    },
    "Relevant Households": {
      description: (
        <span>
          Count of relevant market households by geography filtered for
          OneAmerica target income and demographics
        </span>
      ),
      kpi_priority: false,
    },
    "Submission Share": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Client Submissions</span> to{" "}
          <span className="font-semibold">Relevant Households</span>
        </span>
      ),
      kpi_priority: true,
    },
    "Client Share": {
      description: (
        <span>
          Ratio of <span className="font-semibold">Inforce Clients</span> to{" "}
          <span className="font-semibold">Relevant Households</span>
        </span>
      ),
      kpi_priority: true,
    },
  },
};

export const performanceMetrics_csv = [
  [
    "Metric Category",
    "Metric",
    "Metric Description/Calculation",
    "Prioritized KPIs",
  ],
  [
    "Sales Pipeline",
    "Client Meetings/Calls",
    "Count of in-person meetings or calls held by the agent with prospective customers",
    "",
  ],
  [
    "Sales Pipeline",
    "Client Prospects",
    "Universe of customers identified by the agent as a potential insurance or annuity buyer",
    "X",
  ],
  [
    "Sales Pipeline",
    "Client Submissions",
    "Distinct count of customers who submitted an app with OneAmerica agent",
    "X",
  ],
  [
    "Sales Pipeline",
    "Clients Declined",
    "Distinct count of customers who submitted an app but were declined for underwriting reasons",
    "",
  ],
  [
    "Sales Pipeline",
    "Clients Withdrawn",
    "Distinct count of customers who submitted an app but did not complete the underwriting process",
    "",
  ],
  [
    "Sales Pipeline",
    "Clients Issued",
    "Distinct count of customers who submitted an app and were issued a OneAmerica policy",
    "",
  ],
  [
    "Sales Pipeline",
    "Clients Not Taken",
    "Distinct count of customers who were issued a OneAmerica policy but did not purchase",
    "",
  ],
  [
    "Sales Pipeline",
    "Clients Placed",
    "Distinct count of customers who were issued a OneAmerica policy and purchased",
    "",
  ],
  [
    "Sales Pipeline",
    "Client Submission to Prospect Ratio",
    "Ratio of Client Submissions to Client Prospects",
    "X",
  ],
  [
    "Sales Pipeline",
    "Client Decline Ratio",
    "Ratio of Clients Declined to Client Submissions",
    "",
  ],
  [
    "Sales Pipeline",
    "Client Withdrawn Ratio",
    "Ratio of Clients Withdrawn to Client Submissions",
    "",
  ],
  [
    "Sales Pipeline",
    "Client Issue Ratio",
    "Ratio of Clients Issued to Client Submissions",
    "X",
  ],
  [
    "Sales Pipeline",
    "Client Sold Ratio",
    "Ratio of Clients Placed to Clients Issued",
    "",
  ],
  [
    "Sales Pipeline",
    "Client Not Taken Ratio",
    "Ratio of Clients Not Taken to Clients Issued",
    "",
  ],
  [
    "Sales Pipeline",
    "Client Placement Ratio",
    "Ratio of Client Placed to Client Submissions",
    "X",
  ],
  [
    "Sales Production",
    "Policy Submissions",
    "Count of new business applications based on Received Date",
    "",
  ],
  [
    "Sales Production",
    "Sold Policies",
    "Count of placed policies based on Paid Date",
    "X",
  ],
  [
    "Sales Production",
    "Operational Placement Ratio",
    "Ratio of Sold Policies to Policy Submissions (with 3 month lag for Policy Submissions)",
    "",
  ],
  [
    "Sales Production",
    "Weighted Sales",
    "Sum of annual premium equivalent for Sold Policies",
    "X",
  ],
  [
    "Sales Production",
    "Unweighted Sales",
    "Sum of gross premium sales and deposits for Sold Policies",
    "",
  ],
  [
    "Sales Production",
    "Sold Face Amount",
    "Sum of face amount or death benefit for Sold Policies",
    "",
  ],
  [
    "Sales Production",
    "Average Policy Size",
    "Ratio of Weighted Sales to Sold Policies",
    "X",
  ],
  [
    "Sales Production",
    "Average Sold Face Amount",
    "Ratio of Sold Face Amount to Sold Policies",
    "",
  ],
  [
    "Sales Production",
    "Sold Policies to New Customers",
    "Count of Sold Policies to customers with no prior OneAmerica policy",
    "",
  ],
  [
    "Sales Production",
    "Sold Policies to Inforce Customers",
    "Count of Sold Policies to existing OneAmerica customers with an inforce policy",
    "",
  ],
  [
    "Sales Production",
    "New Business Ratio",
    "Ratio of Sold Policies to New Customers to Sold Policies",
    "",
  ],
  [
    "Sales Production",
    "Cross-Sell Ratio",
    "Ratio of Sold Policies to Inforce Customers to Sold Policies",
    "X",
  ],
  [
    "Sales Production",
    "Product Ratio",
    "Ratio of Sold Policies to Clients Placed",
    "X",
  ],
  [
    "Inforce & Customer Penetration",
    "Inforce Clients",
    "Distinct count of customers with an active OneAmerica policy",
    "X",
  ],
  [
    "Inforce & Customer Penetration",
    "Inforce Policies",
    "Count of active OneAmerica policies inforce",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Inforce Products",
    "Distinct count of Life, Annuity, or Care Solution products across Inforce Clients",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Inforce Premium",
    "Sum of annual premium equivalent for Inforce Policies",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Inforce Face Amount",
    "Sum of face amount or death benefit for Inforce Policies",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Inforce Polices per Client",
    "Ratio of Inforce Policies to Inforce Clients",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Inforce Products per Client",
    "Ratio of Inforce Products to Inforce Clients",
    "X",
  ],
  [
    "Inforce & Customer Penetration",
    "Inforce Premium per Client",
    "Ratio of Inforce Premium to Inforce Clients",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Average Inforce Face Amount",
    "Ratio of Inforce Face Amount to Inforce Policies",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Multi-policy Clients",
    "Distinct count of Inforce Clients that own more than one Inforce Policy",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Multi-product Clients",
    "Distinct count of Inforce Clients that own more than one Inforce Product",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Multi-policy Client Ratio",
    "Ratio of Multi-policy Clients to Inforce Clients",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Multi-product Client Ratio",
    "Ratio of Multi-product Clients to Inforce Clients",
    "X",
  ],
  [
    "Inforce & Customer Penetration",
    "Terminated Policies",
    "Count of Inforce Policies that have lapsed due to death, cancellation, etc. during the reporting period",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Terminated Premium",
    "Sum of Inforce Premium that has lapsed due to death, cancellation, etc. during the reporting period",
    "",
  ],
  [
    "Inforce & Customer Penetration",
    "Policy Lapse Ratio",
    "Ratio of Terminated Policies to Inforce Policies at the start of the reporting period",
    "X",
  ],
  [
    "Inforce & Customer Penetration",
    "Premium Lapse Ratio",
    "Ratio of Terminated Premium to Inforce Premium at the start of the reporting period",
    "",
  ],
  [
    "Product Mix",
    "Care Solutions Sold Policy Mix",
    "Ratio of Sold Policies subset for Care Solutions product to Sold Policies",
    "X",
  ],
  [
    "Product Mix",
    "Care Solutions Weighted Sales Mix",
    "Ratio of Weighted Sales subset for Care Solutions product to Weighted Sales",
    "",
  ],
  [
    "Product Mix",
    "Care Solutions Inforce Premium Mix",
    "Ratio of Inforce Premium subset for Care Solutions product to Inforce Premium",
    "",
  ],
  [
    "Product Mix",
    "Care Solutions Inforce Policy Mix",
    "Ratio of Inforce Policies subset for Care Solutions product to Inforce Policies",
    "X",
  ],
  [
    "Product Mix",
    "Care Solutions Agent Ratio",
    "Ratio of Active Agents subset that has sold a Care Solutions product to Active Agents",
    "X",
  ],
  [
    "Product Mix",
    "Annuity Sold Policy Mix",
    "Ratio of Sold Policies subset for Annuity product to Sold Policies",
    "",
  ],
  [
    "Product Mix",
    "Annuity Weighted Sales Mix",
    "Ratio of Weighted Sales subset for Annuity product to Weighted Sales",
    "",
  ],
  [
    "Product Mix",
    "Annuity Inforce Premium Mix",
    "Ratio of Inforce Premium subset for Annuity product to Inforce Premium",
    "",
  ],
  [
    "Product Mix",
    "Annuity Inforce Policy Mix",
    "Ratio of Inforce Policies subset for Annuity product to Inforce Policies",
    "",
  ],
  [
    "Product Mix",
    "Annuity Agent Ratio",
    "Ratio of Active Agents subset that has sold a Annuity product to Active Agents",
    "",
  ],
  [
    "Agents & Recruiting",
    "Active Agents",
    "Count of producers employed by OneAmerica or independent producers licensed to sell OneAmerica products",
    "X",
  ],
  [
    "Agents & Recruiting",
    "Non-Producing Agents",
    "Count of Active Agents subset that does not have a Sold Policy in the last 12 months",
    "",
  ],
  [
    "Agents & Recruiting",
    "Producing Agents",
    "Count of Active Agents subset that has produced at least one Sold Policy in the last 12 months",
    "",
  ],
  [
    "Agents & Recruiting",
    "Producing Ratio",
    "Ratio of Producing Agents to Active Agents",
    "X",
  ],
  [
    "Agents & Recruiting",
    "Average Agent Production",
    "Ratio of Weighted Sales in the last 12 months divided by Producing Agents",
    "",
  ],
  [
    "Agents & Recruiting",
    "High Producing Agents",
    "Count of Producing Agents that have more than $50K Weighted Sales in the last 12 months",
    "",
  ],
  [
    "Agents & Recruiting",
    "Low Producing Agents",
    "Count of Producing Agents that have less than $10K Weighted Sales in the last 12 months",
    "",
  ],
  [
    "Agents & Recruiting",
    "High Producing Agent Ratio",
    "Ratio of High Producing Agents to Producing Agents",
    "X",
  ],
  [
    "Agents & Recruiting",
    "Low Producing Agent Ratio",
    "Ratio of Low Producing Agents to Producing Agents",
    "X",
  ],
  [
    "Agents & Recruiting",
    "Agent Attrition",
    "Subset of Active Agents that terminated or had their license expired during the reporting period",
    "",
  ],
  [
    "Agents & Recruiting",
    "Agent Attrition Rate",
    "Ratio of Agent Attrition to Active Agents at the start of the reporting period",
    "X",
  ],
  [
    "Agents & Recruiting",
    "New Agents Recruited",
    "Subset of Active Agents that were hired or onboarded during the reporting period",
    "",
  ],
  [
    "Agents & Recruiting",
    "Net Agents Added",
    "Difference between New Agents Recruited and Agent Attrition during the reporting period",
    "X",
  ],
  [
    "Agents & Recruiting",
    "Average Agent Tenure",
    "Average tenure with OneAmerica in years for Active Agents",
    "",
  ],
  [
    "Implied Metrics & Ratios",
    "Market Size",
    "Sum of annualized sales premium for relevant life and annuity products by geography across all carriers",
    "",
  ],
  [
    "Implied Metrics & Ratios",
    "Market Share",
    "Ratio of Weighted Sales to Market Size for life products; ratio of Unweighted Sales to Market Size for annuities",
    "X",
  ],
  [
    "Implied Metrics & Ratios",
    "Market Agents",
    "Count of resident, active producers licensed to sell life and annuity products by geography across all carriers",
    "",
  ],
  [
    "Implied Metrics & Ratios",
    "Agent Share",
    "Ratio of Active Agents to Market Agents (more relevant for independent channel)",
    "X",
  ],
  [
    "Implied Metrics & Ratios",
    "Relevant Households",
    "Count of relevant market households by geography filtered for OneAmerica target income and demographics",
    "",
  ],
  [
    "Implied Metrics & Ratios",
    "Submission Share",
    "Ratio of Client Submissions to Relevant Households",
    "X",
  ],
  [
    "Implied Metrics & Ratios",
    "Client Share",
    "Ratio of Inforce Clients to Relevant Households",
    "X",
  ],
];
