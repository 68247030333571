import React from "react";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";

const Breadcrumbs = ({ crumbs }) => {
  return (
    <section className="-ml-3 relative">
      <div className="flex flex-wrap md:flex-nowrap gap-2 p-1 text-[11px] sm:text-xs w-full md:w-fit">
        {crumbs.map((crumb, index) => (
          <div className="flex gap-1 items-center" key={index}>
            <Link
              to={crumb.url}
              className={`px-2 py-2 rounded-md ${
                crumbs.length === index + 1
                  ? "pointer-events-none text-secondary font-semibold"
                  : "hover:bg-gray-100"
              }`}
            >
              {crumb.title}
            </Link>
            {crumbs.length !== index + 1 && (
              <BsChevronRight className="text-secondary w-5" />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Breadcrumbs;
