import API from "../../api/api";
import jwtDecode from "jwt-decode";

const getUserData = (setLoggedInUserData, setIsUserLoggedIn) => {
  const sessionToken = { session_token: "oa_user_info" };
  if (localStorage.getItem("oa_user_info")) {
    API.post("/get-token")
      .then((response) => {
        if (response.status === 200 && response.data) {
          const accessToken = response.data.accessToken;
          localStorage.setItem(
            "oa_user_info",
            JSON.stringify({ accessToken })
          );
          const decodedToken = jwtDecode(accessToken);
          setIsUserLoggedIn(true);
          setLoggedInUserData({
            id: decodedToken.id,
            company: decodedToken.company,
            username: decodedToken.username,
            fname: decodedToken.fname,
            lname: decodedToken.lname,
            role: decodedToken.role,
            email: decodedToken.email ?? null,
            phone: decodedToken.phone ?? null,
            picture: decodedToken.picture ?? null,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  } else {
    // If JWT Token is not present, the check for session logged in.
    API.post("/get-session", sessionToken)
      .then((response) => {
        if (response.status === 200) {
          setIsUserLoggedIn(true);
          setLoggedInUserData({
            company: response.data.userInfo.company,
            username: response.data.userInfo.username,
            role: response.data.userInfo.role,
            fname: response.data.userInfo.fname,
            lname: response.data.userInfo.lname,
            id: response.data.userInfo.id,
            email: response.data.userInfo.email ?? null,
            phone: response.data.userInfo.phone ?? null,
            picture: response.data.userInfo.picture ?? null,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }
};

export default getUserData;
