import React, { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { VscKey } from "react-icons/vsc";
import API from "../../api/api";
import { FaCheck } from "react-icons/fa";
import { TbLoader2 } from "react-icons/tb";

const passwords = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  session_token: "oa_user_info",
};
const ChangePassword = () => {
  const [showPass, setShowPass] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [inputData, setInputData] = useState(passwords);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnChangePassword = (e) => {
    e.preventDefault();
    setSpinner(true);

    if (
      inputData.oldPassword &&
      inputData.newPassword &&
      inputData.confirmPassword
    ) {
      API.post("/change-password", inputData)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res);
            setSuccess(true);
            setError(null);
            setSpinner(false);
            setTimeout(() => {
              setSuccess(null);
            }, 5000);
            setInputData(passwords);
          }
        })
        .catch((err) => {
          setError(err?.response?.data?.errorResponse);
          setSuccess(null);
          setSpinner(false);
          // console.log(err);
        });
    } else {
      setSpinner(false);
      setError("All fields are required");
    }
  };

  return (
    <section className="md:p-10 flex justify-center">
      <div className="w-80">
        {success === true && (
          <div className="absolute w-full top-0 right-0 font-semibold flex gap-2 items-center justify-center text-white px-4 py-1 rounded-tr-lg rounded-tl-lg bg-green-600">
            <FaCheck />
            <span>Password updated successfully</span>
          </div>
        )}
        <form action="" onSubmit={(e) => handleOnChangePassword(e)}>
          {/* Elegible Current Password */}
          <div>
            <label
              htmlFor="oldPassword"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Current Password
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">
                  <VscKey />
                </span>
              </div>
              <input
                type={showPass ? "text" : "password"}
                onChange={(e) => handleInputChange(e)}
                name="oldPassword"
                id="oldPassword"
                className="block w-full rounded-md border-0 py-1.5 pl-10 pr-20 text-gray-900 ring-1 ring-inset outline-none ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                value={inputData.oldPassword || ""}
              />
            </div>
          </div>
          {/* Input New Password */}
          <div className="my-5">
            <label
              htmlFor="newPassword"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              New Password
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">
                  <VscKey />
                </span>
              </div>
              <input
                type={showPass ? "text" : "password"}
                onChange={(e) => handleInputChange(e)}
                name="newPassword"
                id="newPassword"
                className="block w-full rounded-md border-0 py-1.5 pl-10 pr-20 text-gray-900 ring-1 ring-inset outline-none ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                value={inputData.newPassword || ""}
              />
            </div>
          </div>
          {/* Input Confirm Password */}
          <div className="my-5">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Confirm Password
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">
                  <VscKey />
                </span>
              </div>
              <input
                type={showPass ? "text" : "password"}
                onChange={(e) => handleInputChange(e)}
                name="confirmPassword"
                id="confirmPassword"
                className="block w-full rounded-md border-0 py-1.5 pl-10 pr-20 text-gray-900 ring-1 ring-inset outline-none ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                value={inputData.confirmPassword || ""}
              />
            </div>
          </div>
          {/* Show password button */}
          <div>
            <div
              onMouseDown={() => setShowPass(true)}
              onMouseUp={() => setShowPass(false)}
              className={`text-xs -mt-2 flex justify-end ${
                showPass ? "cursor-grabbing" : "cursor-grab"
              }`}
            >
              {showPass ? (
                <span className="flex gap-2 items-center cursor-pointer">
                  <FaRegEyeSlash />
                  Hide Passwords
                </span>
              ) : (
                <span className="flex gap-2 items-center cursor-pointer">
                  <FaRegEye />
                  Show Passwords
                </span>
              )}
            </div>
          </div>
          {/* Action Buttons */}
          <div className="grid grid-cols-3 gap-3 mt-5">
            <input
              type="reset"
              className="bg-gray-500 rounded-lg text-white p-2 w-full cursor-pointer hover:bg-opacity-80"
              value={"Reset"}
              onClick={(e) => setInputData(passwords)}
            />
            <button
              onClick={(e) => {
                handleOnChangePassword(e);
              }}
              className="bg-secondary flex justify-center items-center rounded-lg text-white p-2 w-full col-span-2 hover:bg-opacity-80"
            >
              {spinner ? (
                <TbLoader2 className="animate-spin text-gray-100 text-xl" />
              ) : (
                "Change"
              )}
            </button>
          </div>
          {error && (
            <div className="font-semibold mt-5 text-sm text-center text-red-500">
              {error}
            </div>
          )}
        </form>
        <div className="mt-5 text-sm">
          <span
            className="font-semibold cursor-pointer hover:underline underline-offset-2"
            onClick={() => setShowInstructions(!showInstructions)}
          >
            {showInstructions ? "Hide " : "Show "} Instructions
          </span>
          {showInstructions && (
            <ul className="list-disc ml-2 grid gap-2">
              <li>
                The password must meet the following requirements:
                <ul className="ml-1">
                  <li>Between 8 and 16 characters</li>
                  <li>At least 1 uppercase letter</li>
                  <li>At least 1 lowercase letter</li>
                  <li>At least 1 number</li>
                  <li>At least 1 special character ( ! @ # $ % ^ & * _ )</li>
                </ul>
              </li>
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
