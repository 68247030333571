import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { breadCrumbs, naiad2024, deployment2024 } from "../../data/data";
import FilterDeployment from "../../components/modules/FilterDeployment";
import { selectedDeployment } from "../../data/staticData";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const Naiad2024 = () => {
  const { setPageTitle, userActivityTracker } = useContext(UserContext);
  const [deployment, setDeployment] = useState(
    localStorage.getItem("salesprophet-deployment-title") &&
      localStorage.getItem("salesprophet-deployment-value")
      ? {
          title: localStorage.getItem("salesprophet-deployment-title"),
          slug: localStorage.getItem("salesprophet-deployment-value"),
        }
      : {
          title: naiad2024.deployments[0].title,
          slug: naiad2024.deployments[0].slug,
        }
  );

  useEffect(() => {
    setPageTitle(naiad2024.title);
    userActivityTracker("Page changed", naiad2024?.title);
  }, [deployment]);

  return (
    <section className="p-5 pt-0 w-full md:min-h-[35rem]">
      <div className="flex flex-col md:flex-row justify-between">
        <Breadcrumbs crumbs={breadCrumbs(naiad2024)} />
      </div>
      <div className="w-full my-10 pb-10 relative flex flex-col gap-20 transition-all duration-300 ease-in-out items-center">
        <div className="md:px-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:w-3/4 h-full gap-5 items-center justify-center transition-all duration-300 ease-in-out">
          {deployment?.title === deployment2024.title &&
            naiad2024.deployment2024?.map((module, index) => (
              <Link
                key={index}
                to={
                  module.slug === "#"
                    ? "#"
                    : // : naiad2024.slug + deployment2024.slug + module.slug
                      naiad2024.slug + module.slug
                }
                className="w-full h-full min-h-[10rem] py-10 px-7 flex flex-col gap-2 relative text-gray-700 hover:text-white border hover:border-primary bg-primary bg-opacity-5 hover:bg-opacity-100 rounded-2xl shadow-lg"
              >
                <span className="absolute text-4xl top-2 right-3 opacity-10">
                  {naiad2024.icon}
                </span>
                <div className="flex flex-col gap-2 items-center">
                  <span className="p-1 rounded-full text-3xl w-fit">
                    {module.icon}
                  </span>
                  <h3 className="font-semibold">{module.title}</h3>
                </div>
                <p className="text-xs">{module.description}</p>
              </Link>
            ))}
        </div>
      </div>
      {/* Powered By Spinnaker */}
      <div className="fixed hidden md:flex flex-col gap-10 right-5 bottom-5">
        <div className="w-full flex flex-col items-end scale-75 xl:scale-90">
          <p className="text-[10px] 2xl:text-xs">Powered by</p>
          <img
            src={spinnakerLogo}
            alt="powered by spinnaker analytics"
            className="w-28"
          />
        </div>
      </div>
    </section>
  );
};

export default Naiad2024;
