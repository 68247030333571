import React from 'react'

const QuickWins = () => {
  return (
    <iframe
      src="https://spinnakerwebsiteassets.s3.us-east-1.amazonaws.com/spinnaker.online/client/oneamerica/Project+Naiad/Quick+Wins/Spinnaker-OneAmerica+Project+Naiad+Board+Presentation+vF.pdf"
      frameborder="0"
      className="w-full h-[85vh]"
    ></iframe>
  )
}

export default QuickWins