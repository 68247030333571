import React, { useContext } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { PowerBiPermissionScopes } from "./authConfig";
import { useEffect, useState } from "react";
import axios from "axios";
import { UserContext } from "../../context/userContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SiPowerbi } from "react-icons/si";

const img =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/auth/pbi-login.webp";

const PowerBI = ({ reportId, note = null }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [report, setReport] = useState(null);
  const { pbiAccessToken, setPbiAccessToken, setSaveBeforePathPBI } =
    useContext(UserContext);

  const fetchReport = () => {
    axios
      .get(`https://api.powerbi.com/v1.0/myorg/reports/${reportId}`, {
        headers: {
          Authorization: `Bearer ${pbiAccessToken}`,
        },
      })
      .then((res) => {
        // console.log(res);
        setReport(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigate = useNavigate();
  const location = useLocation();

  // console.log(isAuthenticated);

  // Get token behind the scenes without making user login again
  const getAccessToken = () => {
    const accessTokenRequest = {
      scopes: PowerBiPermissionScopes,
      account: account,
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((res) => {
        // console.log("Access Token Client (Refresh Token)\n", res);
        setPbiAccessToken(res.accessToken);
        localStorage.setItem("token_info", JSON.stringify(res));
      })
      .catch((err) => {
        // Comment on production
        // console.log("Refresh Token Error\n", err);
        navigate("/microsoft/oauth2/v2.0/authorize");
      });
  };

  // Use effect to continuously check if token is about to expire and call a function to generate a new one
  useEffect(() => {
    if (pbiAccessToken) {
      const showReport = async () => {
        if (isTokenExpired(pbiAccessToken)) {
          await getAccessToken();
        }
        fetchReport();
      };
      showReport().catch((err) => {
        console.log(err);
      });
    } else {
      if (isAuthenticated) {
        getAccessToken();
      }
    }
  }, [pbiAccessToken]);

  const embedConfig = {
    type: "report",
    id: reportId,
    embedUrl: report?.embedUrl,
    accessToken: pbiAccessToken,
    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad if you're embedding for your organization.
    settings: {
      navContentPaneEnabled: false,
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  };

  const eventHandlers = new Map([
    [
      "loaded",
      () => {
        // console.log("Report loaded");
      },
    ],
    [
      "rendered",
      () => {
        // console.log("Report rendered");
      },
    ],
    [
      "error",
      (event) => {
        // console.log("Error Power Bi: ", event.detail);
        if (event.detail.message === "TokenExpired") {
          getAccessToken();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      },
    ],
    [
      "visualClicked",
      () => {
        if (isTokenExpired && isAuthenticated) {
          getAccessToken();
        }
      },
    ],
    // ["pageChanged", (event) => console.log(event)],
  ]);

  const getEmbeddedComponent = (embeddedReport) => {
    // console.log(embeddedReport);
    window.report = embeddedReport;
  };

  const isTokenExpired = async () => {
    const tokenInfo = await JSON.parse(localStorage.getItem("token_info"));
    // console.log("tokenInfo", tokenInfo);
    const expiryTime = new Date(tokenInfo.expiresOn);
    const currentTime = new Date();
    if (currentTime < expiryTime) {
      setPbiAccessToken(tokenInfo.accessToken);
      // console.log(tokenInfo);
    } else {
      getAccessToken();
    }
  };

  // Enter full screen mode
  const fullScreenModeOn = () => {
    document.getElementById("dashboard").requestFullscreen();
  };

  // save location
  useEffect(() => {
    if (
      location?.pathname !== "/microsoft/oauth2/v2.0/authorize" &&
      location?.pathname !== "/login" &&
      location?.pathname !== "/forgot-password"
    ) {
      localStorage.setItem("last-known-path", location.pathname);
    }
    setSaveBeforePathPBI(localStorage?.getItem("last-known-path"));
  }, [location]);

  useEffect(() => {
    // for every 58 minutes
    const interval = setInterval(() => {
      if (isAuthenticated) {
        getAccessToken();
      }
    }, 3500 * 1000);
    return clearInterval(interval);
  });

  return isAuthenticated ? (
    <>
      <div
        className={`flex ${
          note ? "justify-between" : "justify-end"
        } items-center w-full`}
      >
        {note && <p className="text-sm mb-5">{note}</p>}
        <div
          className="px-3 py-2 -mt-3 mb-2 text-xs w-fit cursor-pointer rounded-md bg-primary text-white hover:opacity-80"
          onClick={() => fullScreenModeOn()}
        >
          Full Screen
        </div>
      </div>
      <div id="dashboard">
        <PowerBIEmbed
          embedConfig={embedConfig}
          eventHandlers={eventHandlers}
          cssClassName={"h-screen rounded-lg overflow-auto"}
          getEmbeddedComponent={getEmbeddedComponent}
        />
      </div>
    </>
  ) : (
    <>
      <div className="relative items-center flex flex-col justify-center text-[10px] md:text-xs lg:text-base">
        {/* Sign In to PowerBI Block */}
        {isAuthenticated !== true && (
          <>
            <p className="text-center mt-10">
              To view this dashboard, please login with your OneAmerica
              Microsoft 365 account
            </p>
            <Link
              className={`flex gap-2 w-fit h-fit mt-10 p-4 mr-5 border items-center border-yellow-400 hover:shadow-md shadow-yellow-400 rounded-md text-[10px] xl:text-xs
                bg-white `}
              to={"/microsoft/oauth2/v2.0/authorize"}
              id="loginPowerBI"
            >
              <SiPowerbi className={`text-lg text-yellow-400`} />
              <span>Dashboard Login</span>
            </Link>
            {/* <span className="w-24 h-10 absolute -z-10 mt-16 -ml-5 rounded-lg bg-yellow-400 animate-ping"></span> */}
          </>
        )}
        {/* <img src={img} alt="Dashboard login" className="w-1/3 -ml-5" /> */}
      </div>
    </>
  );
};

export default PowerBI;
