// ***************************************************************   All Icons Import  ***************************************************************
import { FaAddressCard, FaGlobeAmericas, FaUserTie } from "react-icons/fa";
import { dashboards } from "./dashboards";
import { FaChartLine, FaMagnifyingGlassDollar, FaPersonSwimming } from "react-icons/fa6";
import { GoHomeFill } from "react-icons/go";
import { LuFileBarChart } from "react-icons/lu";
import { TbWorldDollar } from "react-icons/tb";
import { performanceMetrics } from "./modules/PerformanceMetrics";

// ***************************************************************   All Industries  ***************************************************************
export const deployment2024 = {
  title: "2024 Deployment",
  slug: "/2024-deployment",
  name: "deployment2024",
};

// ***************************************************************   All Solutions  ***************************************************************
// Naiad 2024
export const naiad2024 = {
  title: "Naiad 2024",
  slug: "/naiad-2024",
  // slug: "#",
  icon: <FaPersonSwimming />,
  description: "",
  deployments: [deployment2024],
  deployment2024: [
    {
      title: "Market Intelligence",
      slug: "/market-intelligence",
      // slug: "#",
      icon: <FaGlobeAmericas />,
      description:
        "Geographic assessment of OneAmerica sales performance compared to insurance market sales",
      dashboards: { ...dashboards.naiad2024.marketIntelligence },
    },
    {
      title: "Customer Segmentation",
      slug: "/customer-segmentation",
      // slug: "#",
      icon: <FaAddressCard />,
      description:
        "Analysis of OneAmerica inforce customers by demographic, product basket, and more",
      dashboards: { ...dashboards.naiad2024.customerSegmentation },
    },
    {
      title: "Agent Productivity",
      slug: "/agent-productivity",
      // slug: "#",
      icon: <FaUserTie />,
      description:
        "Evaluation of agent sales performance by individual producer (writing agent) and producer cohorts",
      dashboards: { ...dashboards.naiad2024.agentProductivity },
    },
    {
      title: "Sales Opportunity",
      slug: "/sales-opportunity",
      // slug: "#",
      icon: <FaMagnifyingGlassDollar />,
      description:
        "Estimate of incremental sales and recruiting potential to achieve growth in underserved markets",
      dashboards: { ...dashboards.naiad2024.salesOpportunity },
    },
    {
      title: "Quick Wins",
      slug: "/quick-wins",
      // slug: "#",
      icon: <FaChartLine />,
      description: "High-level summary of quick win and medium term levers, market classification, and recruiting prioritization",
    },
    { 
      title: "Metrics for Performance Tracking",
      slug: "/metrics-for-performance-tracking",
      // slug: "#",
      icon: <LuFileBarChart />,
      description:
        "Recommended list of metrics and KPIs for performance tracking against growth plan",
        performanceMetrics: performanceMetrics
    },
  ],
};

// Breadcrumbs
export const breadCrumbs = (item) => [
  {
    title: "Home",
    url: "/home",
  },
  {
    title: item.title,
    url: item.slug,
  },
];

// ***************************************************************  Solutions in Home  ***************************************************************
export const home = [naiad2024];

// ***************************************************************   Menu  ***************************************************************
export const menu = [
  {
    title: "Home",
    slug: "/home",
    icon: <GoHomeFill />,
  },
  ...home,
];
